<template>
  <c-box
    id="kuisioner"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Formulir Gizi', isCurrent: true },
        ]"
      />
    </Portal>
    <Header />
    
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      bg="#FFF"
      :p="['1rem', '0px']"
    >
      <Steps
        :current="6"
        :total="6"
      />
      <c-box
        w="100%"
        max-width="590px"
        mx="auto"
        :margin-top="['16px', '24px']"
      >
        <c-box text-align="center">
          <c-text
            color="primary.400"
            font-family="roboto"
            text-transform="uppercase"
            font-weight="500"
            :font-size="['16px', '18px']"
            margin-bottom="10px"
          >
            Formulir Gizi (6/6)
          </c-text>
          <c-heading
            font-weight="700"
            :font-size="['18px', '28px']"
            :line-height="['27px', '42px']"
            color="black.900"
          >
            Kondisi Pribadi &amp; Riwayat Medis
          </c-heading>
        </c-box>
        <form 
          v-chakra
          marginTop="24px"
          @submit.prevent="submit"
        >
          <c-box
            v-chakra="{
              'input[type=radio]:checked': {
                bg: 'primary.400',
                borderColor: 'primary.400',
              },
              'input[type=checkbox]:checked': {
                bg: 'primary.400',
                borderColor: 'primary.400',
              },
              div: {
                fontSize: ['14px', '18px'],
              },
            }"
          >
            <FormAccordionList allow-toggle>
              <FormAccordionListItem
                label="Riwayat Medis"
                :is-error="!isSectionMedicalHistoryValid"
              >
                <FormCheckbox
                  v-model="mentalDisorder_"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.mentalDisorder.$invalid"
                  label="Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?"
                  :options="OPTIONS_MENTAL_DISORDER"        
                  disabled-option="92"
                  :is-other="true"
                  other-option="87"
                  :other="mentalDisorderOther"
                  @updateOther="updateMentalDisorderOther"
                />
                <FormCheckbox
                  v-model="disease_"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.disease.$invalid"
                  label="Adakah saat ini kamu dinyatakan mengidap salah satu kondisi di bawah ini oleh dokter?"
                  :options="OPTIONS_DISEASE"   
                  disabled-option="139"
                  :is-other="true"
                  other-option="106"
                  :other="diseaseOther"
                  @updateOther="updateDiseaseOther"
                />
                <FormCheckbox
                  v-model="symptom"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.symptom.$invalid"
                  label="Adakah kamu mengalami keluhan dibawah ini dalam 3 hari terakhir?"
                  :options="OPTIONS_SYMPTOM"   
                  disabled-option="107"
                />
                <FormInput
                  v-model="drugsConsumption"
                  type="textarea"
                  :is-disabled="!isEditable"
                  :is-required="false"
                  :is-invalid="$v.drugsConsumption.$invalid"
                  label="Adakah konsumsi obat-obatan rutin yang perlu kami ketahui? Jika ada, mohon sebutkan obat untuk apa atau merk obatnya"
                  placeholder="Contoh: obat diabetes, obat hipertensi, dexamethasone, metformin, captopril."
                />
                <FormInput
                  v-model="specialMedicine"
                  type="text"
                  :is-disabled="!isEditable"
                  :is-required="false"
                  :is-invalid="$v.specialMedicine.$invalid"
                  label="Adakah konsumsi jenis obat, ramuan herbal atau minuman khusus yang sedang digunakan untuk diet? Jika ada, mohon sebutkan jenisnya dan tujuan penggunaannya"
                  placeholder="Tuliskan disini"
                />
                <FormCheckbox
                  v-model="currentBehavior"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.currentBehavior.$invalid"
                  label="Adakah kamu termasuk salah satu dibawah ini?"
                  :options="OPTIONS_CURRENT_BEHAVIOR"  
                  disabled-option="116"
                />
                <FormInput
                  v-model="supplementConsumption"
                  type="text"
                  :is-disabled="!isEditable"
                  :is-required="false"
                  :is-invalid="$v.supplementConsumption.$invalid"
                  label="Adakah jenis multivitamin atau tablet suplementasi yang kamu konsumsi? Jika ada, mohon sebutkan jenisnya atau merknya"
                  placeholder="Tuliskan disini"
                />
                <FormRadio
                  v-model="communicationIntensity"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.communicationIntensity.$invalid"
                  label="Seperti apa intensitas komunikasi yang kamu inginkan dalam program?"
                  :options="OPTIONS_COMMUNICATION_INTENSITY"        
                />
                <FormInput
                  v-model="otherDisease"
                  type="textarea"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.otherDisease.$invalid"
                  label="Adakah riwayat penyakit atau kondisi kesehatan lainnya yang perlu kami ketahui?"
                  placeholder="Tuliskan disini"
                />
                <!-- <template v-slot:default>
                </template> -->
              </FormAccordionListItem>
              
              <FormAccordionListItem
                label="Kondisi Pribadi"
                :is-error="!isSectionPersonalConditionValid"
              >
                <FormSelect 
                  v-model="jobSelection"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.job.$invalid"
                  label="Pekerjaan"
                  placeholder="Pilih Pekerjaan"
                  :options="OPTIONS_JOBS"
                  :is-other="true"
                  :other="jobOther"
                  other-option="125"
                  @updateOther="updateJobOther"
                />
                <FormSelect 
                  v-model="educationSelection"
                  :is-disabled="!isEditable"
                  is-required
                  :is-invalid="$v.lastEducation.$invalid"
                  label="Pendidikan Terakhir"
                  placeholder="Pilih Pendidikan"
                  :options="OPTIONS_LAST_EDUCATION"
                  :is-other="true"
                  :other="educationOther"
                  other-option="131"
                  @updateOther="updateEducationOther"
                />
                <FormInput
                  v-model="expectProgram"
                  type="textarea"
                  :is-disabled="!isEditable"
                  :is-required="false"
                  :is-invalid="$v.expectProgram.$invalid"
                  label="Apa yang kamu harapkan dari program yang kamu pilih?"
                  placeholder="Tuliskan disini"
                />
                <FormInput
                  v-model="expectNutritionist"
                  type="textarea"
                  :is-disabled="!isEditable"
                  :is-required="false"
                  :is-invalid="$v.expectNutritionist.$invalid"
                  label="Apa lagi yang kamu harapkan dari Ahli Gizi Dietela?"
                  placeholder="Silahkan tuliskan sikap, cara komunikasi, atau jenis-jenis informasi yang kamu harapkan dari Ahli Gizi kamu"
                />
                <!-- <template v-slot:default>
                </template> -->
              </FormAccordionListItem>
            </FormAccordionList>
            <c-grid
              margin-top="16px"
              template-columns="repeat(2, 1fr)" 
              gap="3"
            >
              <c-button
                w="100%"
                background-color="white"
                color="primary.400"
                border-color="primary.400"
                border-radius="100px"
                variant="outline"
                px="30px"
                h="48px"
                :is-disabled="isSubmitting || isBack"
                @click="onPrevious"
              >
                Sebelumnya
              </c-button>
              <c-button
                w="100%"
                background-color="primary.400"
                color="white"
                border-radius="100px"
                variant="solid"
                px="30px"
                :is-disabled="isDisabled || isBack"
                :is-loading="isSubmitting"
                loading-text="Submitting"
                h="48px"
                type="submit"
              >
                Selanjutnya
              </c-button>
            </c-grid>
          </c-box>
        </form>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import Steps from '@/components/steps'
// import Breadcrumb from '@/components/breadcrumb'
// import DinamisForm from '@/views/client/kuisioner/dinamis-form'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select'
import FormInput from '@/views/client/kuisioner/forms/form-input'
import FormRadio from '@/views/client/kuisioner/forms/form-radio'
import FormCheckbox from '@/views/client/kuisioner/forms/form-checkbox'
import {
  OPTIONS_MENTAL_DISORDER,
  OPTIONS_DISEASE,
  OPTIONS_SYMPTOM,
  OPTIONS_CURRENT_BEHAVIOR,
  OPTIONS_COMMUNICATION_INTENSITY,
  OPTIONS_JOBS,
  OPTIONS_LAST_EDUCATION,
} from '@/constants/questionnaire'
import FormAccordionList from '@/views/client/kuisioner/forms/form-accordion/list.vue'
import FormAccordionListItem from '@/views/client/kuisioner/forms/form-accordion/list-item.vue'

export default {
  name: 'FormulirGizi6Page',
  components: {
    FormAccordionListItem,
    FormAccordionList,
    BreadcrumbPath,
    // DinamisForm,
    // Breadcrumb,
    Steps,
    FormSelect,
    FormInput,
    FormRadio,
    FormCheckbox,
  },
  data() {
    return {
      // riwayat medis
      mentalDisorder_: [],
      mentalDisorderOther: null,
      disease_: [],
      diseaseOther: null,
      symptom: [],
      drugsConsumption: null,
      specialMedicine: null,
      currentBehavior: [],
      supplementConsumption: null,
      communicationIntensity: null,
      otherDisease: null,

      // kondisi pribadi
      jobSelection: null,
      jobOther: null,
      educationSelection: null,
      educationOther: null,
      expectProgram: null,
      expectNutritionist: null,
      
      isSubmitting: false,
      isBack: false,

      // -- dinamis form
      questions: [],
      
      OPTIONS_MENTAL_DISORDER,
      OPTIONS_DISEASE,
      OPTIONS_SYMPTOM,
      OPTIONS_CURRENT_BEHAVIOR,
      OPTIONS_COMMUNICATION_INTENSITY,
      OPTIONS_JOBS,
      OPTIONS_LAST_EDUCATION,
    }
  },
  validations: {
    mentalDisorder: {
      required,
      $each: {
        value: { required },
        other: { required: requiredIf((model) => model.value === '87') },
      },
    },
    disease: {
      required,
      $each: {
        value: { required },
        other: { required: requiredIf((model) => model.value === '106') },
      },
    },
    symptom: { required },
    drugsConsumption: {},
    specialMedicine: {},
    currentBehavior: { required },
    supplementConsumption: {},
    communicationIntensity: { required },
    otherDisease: { required },

    job: { required },
    lastEducation: { required },
    expectProgram: {},
    expectNutritionist: {},
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.medicalHistory,
      informedConsent: (s) => s.kuisioner.informedConsent,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    isSectionMedicalHistoryValid() {
      return !this.$v.mentalDisorder.$invalid
            && !this.$v.disease.$invalid
            && !this.$v.symptom.$invalid
            && !this.$v.drugsConsumption.$invalid
            && !this.$v.specialMedicine.$invalid
            && !this.$v.currentBehavior.$invalid
            && !this.$v.supplementConsumption.$invalid
            && !this.$v.communicationIntensity.$invalid
            && !this.$v.otherDisease.$invalid
    },
    isSectionPersonalConditionValid() {
      return !this.$v.job.$invalid
            && !this.$v.lastEducation.$invalid
            && !this.$v.expectProgram.$invalid
            && !this.$v.expectNutritionist.$invalid
    },
    mentalDisorder() {
      return this.$store.state.kuisioner.medicalHistory?.mentalDisorder ?? []
    },
    disease() {
      return this.$store.state.kuisioner.medicalHistory?.disease ?? []
    },
    job() {
      return this.jobSelection === '125' ? this.jobOther : this.jobSelection
    },
    lastEducation() {
      return this.educationSelection === '131'
        ? this.educationOther
        : this.educationSelection
    },
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    isDisabled() {
      return this.$v.$invalid
    },
    value() {
      return {
        mentalDisorder: this.mentalDisorder,
        disease: this.disease,
        symptom: this.symptom,
        drugsConsumption: this.drugsConsumption,
        specialMedicine: this.specialMedicine,
        currentBehavior: this.currentBehavior,
        supplementConsumption: this.supplementConsumption,
        communicationIntensity: this.communicationIntensity,
        otherDisease: this.otherDisease,
        job: this.job,
        lastEducation: this.lastEducation,
        expectNutritionist: this.expectNutritionist,
        expectProgram: this.expectProgram,
      }
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    $data: {
      handler: _.debounce(function() {
        if (!this.isSubmitting && !this.isBack) {
          const mentalDisorder = this.mentalDisorder_?.map((it) => {
            if (it == '87') {
              return {
                value: it,
                other: this.mentalDisorderOther,
              }
            } else {
              return {
                value: it,
              }
            }
          })
          const disease = this.disease_?.map((it) => {
            if (it == '106') {
              return {
                value: it,
                other: this.diseaseOther,
              }
            } else {
              return {
                value: it,
              }
            }
          })
          const value = {
            mentalDisorder: mentalDisorder,
            disease: disease,
            symptom: this.symptom,
            drugsConsumption: this.drugsConsumption,
            specialMedicine: this.specialMedicine,
            currentBehavior: this.currentBehavior,
            supplementConsumption: this.supplementConsumption,
            communicationIntensity: this.communicationIntensity,
            otherDisease: this.otherDisease,
            job: this.job,
            lastEducation: this.lastEducation,
            expectNutritionist: this.expectNutritionist,
            expectProgram: this.expectProgram,
          }
          this.setStoreMedicalHistory(value)
        }
      }, 100),
      deep: true,
    },
  },
  async created() {
    if (this.currentStep && this.currentStep != 6) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    } else if (this.currentStep == null) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/statement`)
    } else if (!this.informedConsent?.isAgreeInformedConsent) {
      const res = await this.reqStoreAnswerQuestionnaireDraft({
        programId: this.programId,
        filter: 'informedConsent',
      })
      if (!res?.informedConsent?.isAgreeInformedConsent) {
        await this.$store.dispatch('kuisioner/updateCurrentStep', 0)
        this.$router.replace(`/quizionary/${this.$route.params.programId}/statement`)
      }
    }
  },
  async mounted() {
    await this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'medicalHistory',
    })
    if (this.kuisData?.mentalDisorder) {
      this.mentalDisorder_ = this.kuisData?.mentalDisorder.map((it) => it.value)
      this.mentalDisorderOther = this.kuisData?.mentalDisorder.find((it) => it.value == '87')?.other
    }
    if (this.kuisData?.disease) {
      this.disease_ = this.kuisData?.disease.map((it) => it.value)
      this.diseaseOther = this.kuisData?.disease.find((it) => it.value == '106')?.other
    }
    this.symptom = this.kuisData?.symptom ? this.kuisData?.symptom : []
    this.drugsConsumption = this.kuisData?.drugsConsumption
    this.specialMedicine = this.kuisData?.specialMedicine
    this.currentBehavior = this.kuisData?.currentBehavior ? this.kuisData?.currentBehavior : []
    this.supplementConsumption = this.kuisData?.supplementConsumption
    this.communicationIntensity = this.kuisData?.communicationIntensity
    this.otherDisease = this.kuisData?.otherDisease

    this.jobSelection = this.OPTIONS_JOBS.some((it) => it.value == this.kuisData?.job) ? this.kuisData?.job : (this.kuisData?.job ? '125' : null)
    this.jobOther = this.OPTIONS_JOBS.some((it) => it.value != this.kuisData?.job) ? this.kuisData?.job : null
    this.educationSelection = this.OPTIONS_LAST_EDUCATION.some((it) => it.value == this.kuisData?.lastEducation) ? this.kuisData?.lastEducation : (this.kuisData?.lastEducation ? '131' : null)
    this.educationOther = this.OPTIONS_LAST_EDUCATION.some((it) => it.value != this.kuisData?.lastEducation) ? this.kuisData?.lastEducation : null
    this.expectNutritionist = this.kuisData?.expectNutritionist
    this.expectProgram = this.kuisData?.expectProgram
  },
  methods: {
    ...mapMutations({
      setStoreMedicalHistory: 'kuisioner/setMedicalHistory',
      setPendingQuisionary: 'clients/setPendingQuisionary',
      setCompletedQuisionary: 'clients/setCompletedQuisionary',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    updateMentalDisorderOther(value) {
      this.mentalDisorderOther = value
    },
    updateDiseaseOther(value) {
      this.diseaseOther = value
    },
    updateJobOther(value) {
      this.jobOther = value
    },
    updateEducationOther(value) {
      this.educationOther = value
    },
    async submit() {
      try {
        if (this.isEditable) {
          this.$v.$touch()
          if (this.$v.$invalid) return
      
          this.isSubmitting = true
          await this.$store.dispatch('kuisioner/setMedicalHistory', {
            programId: this.$route.params.programId,
            isDraft: true,
            value: this.value,
            answers: this.answers,
          })

          await this.$store.dispatch('kuisioner/postV2', this.programId)
          await this.$store.dispatch('kuisioner/clear')
          this.setPendingQuisionary(false)
        }
  
        await this.$store.dispatch('kuisioner/updateCurrentStep', 0)
        await this.$store.commit('kuisioner/resetMedicalHistory')
        this.$router.push({
          name: 'client.quizionary.done',
          query: { kuesioner_id: this.$route.params.programId },
        }).then(() => {
          this.setCompletedQuisionary(true)
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
        if (e.response?.data?.message === 'Choose Nutritionist First') {
          this.$router.push({
            name: 'client.nutritionists',
          })
        }
      } finally {
        this.isSubmitting = false
      }
    },
    async onPrevious() {
      this.isBack = true
      await this.$store.dispatch('kuisioner/updateCurrentStep', 5)
      await this.$store.commit('kuisioner/resetMedicalHistory')
      this.$router.push({
        name: 'client.kuisioner5',
        params: this.$route.params,
      })
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CCollapse"] {
  position: relative;
}
</style>
